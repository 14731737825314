import React from "react"

import DefaultLayout from "../layouts/default"
import TwoThirdsGrid from "../components/layout/TwoThirdsGrid"
import RoundedImage from "../components/RoundedImage"
import Text from "../components/text/Text"
import HeadlineWithSub from "../components/text/HeadlineWithSub"
import DottedLineHorizontal from "../components/DottedLineHorizontal"

const active = {
  title: "Mal- & Gestaltungstherapie"
}

const MalUndGestaltungstherapie = () => 
<DefaultLayout image="/assets/images/IMG_7007.jpeg" active={active}>
  <TwoThirdsGrid textRight>
    <div>
      <HeadlineWithSub>
        <h1>Mal- und Gestaltungs&shy;therapie</h1>
        <h4>
          Sprache plus, Gestaltung, Handlungsraum, Betrachtung
        </h4>
      </HeadlineWithSub>
      <div className="mobileDisplayNone"><RoundedImage image="/assets/images/UNADJUSTEDNONRAW_thumb_5d4d.jpg" contentImage/></div>
    </div>
    <Text>
      <p>
        <strong>
          Die Mal- und Gestaltungstherapie ist eine Fachrichtung der Kunsttherapie. Seelische Prozesse und Phantasien zeigen sich im Bild oft umfassender und leichter als in Worten. Die Mal- und Gestaltungstherapie bietet die Möglichkeit, die Voraussetzungen für Interaktion und sprachliche Kommunikation zu aktivieren und einen Zugang zur eigenen inneren Welt zu schaffen. Innere Bilder können vom Unbewussten ins Bewusste getragen werden. Das Unbewusste, das sich Andeutende, das Werdende kann erfahren und sichtbar gemacht werden. Dort – im Bewussten – sind sie spür- und wandelbar. 
        </strong>
      </p>
      <div className="tdDisplayNone"><RoundedImage image="/assets/images/UNADJUSTEDNONRAW_thumb_5d4d.jpg" contentImage /></div>
      <p>
        Das Potenzial der Klientin und die Lösungsmöglichkeiten zeigen sich oft in der Gestaltung. Zugänglich gemachte Ressourcen können zu Stabilisierung und Wachstum führen. Mal- und Gestaltungstherapie ist eine ganzheitliche Therapieform, die bei den Ressourcen, den gesunden Kräften der Klient*innen ansetzt, in welcher die körperlichen, seelischen und sozialen Aspekte berücksichtigt werden.
      </p>
      <p>
        Die neuen Erfahrungen können das Blickfeld erweitern und Handlungsräume eröffnen, können Flexibilität, Konzentration und persönliches Wachstum zulassen. In der Mal- und Gestaltungstherapie wird der Mensch in seinem Prozess, bezogen auf Lebensgestaltung, Entwicklung und Selbstwerdung, begleitet und unterstützt.  
      </p>
      <p>
        Kunsttherapie geht davon aus, dass die individuelle Gestaltungskraft nicht von Problemen oder Krankheit erfasst wird und somit eine beständige Energiequelle ist. Im Therapieprozess wird versucht, die Klientin wieder mit diesem schöpferischen Potential zu verbinden und ihm mit der künstlerischen Tätigkeit, aktive und wandlungsfähige Bearbeitung zu ermöglichen. 
      </p>
      <p>
        Mal- und Gestaltungstherapie ist für Kinder, Jugendliche und erwachsene Personen geeignet und findet im Atelier statt. Auf Wunsch und nach Absprache kann Mal- und Gestaltungstherapie in Kombination mit Naturerfahrungen  oder im Garten stattfinden. 
      </p>
       <DottedLineHorizontal />
      <p>
        <strong>Durchführungsort</strong><br />
        Rorschacherstrasse 109 A<br />
        9000 St. Gallen
        <br /><br />
        Oder nach Absprache
      </p>
    </Text>
  </TwoThirdsGrid>
</DefaultLayout>

export default MalUndGestaltungstherapie